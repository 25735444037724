/* eslint-disable import/prefer-default-export */
import scriptLoader from 'saddlebag-scriptloader';

const componentName = 'pokemon';
const sharedLibsVersion = '3.1.0';
const reactVersion = '17.0.2';
const reactDomVersion = '17.0.2';

export const loadExternalScripts = () =>
  scriptLoader.queue(
    componentName,
    [
      `/sttc/shared-libs/${sharedLibsVersion}/react/react${reactVersion}.js`,
      `/sttc/shared-libs/${sharedLibsVersion}/react-dom/react-dom${reactDomVersion}.js`,
    ],
    { async: false },
  );
